import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import About from "./About";
import Location from "./Location/Location";
import DesktopFooter from "./Footer/DesktopFooter";
import Footer, { FOOTER_HEIGHT_REM } from "./Footer/Footer";
import LangSwitcher from "./Footer/LangSwitcher";
import PanoMap from "./PanoMap/PanoMap";
import Search from "./Search/Search";
import { LocationFilters } from "./utils";

export const BackgroundStyle = {
  LIGHT: "LIGHT",
  DARK: "DARK",
};

const App = () => {
  // get the viewport height multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01;
  const [introMode, setIntroMode] = useState(true);
  const [locations, setLocations] = useState([]);
  const [cities, setCities] = useState([]);
  const isMobileDevice = useMediaQuery({
    query: "(max-device-width: 1224px)",
  });
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)",
  });
  const [backgroundStyle, setBackgroundStyle] = useState(BackgroundStyle.DARK);
  const [locationFilter, setLocationFilter] = useState(LocationFilters.CITIES);

  useEffect(() => {
    fetch("/data/cities.json")
      .then((response) => response.json())
      .then((cities) =>
        cities.map((city) => ({
          ...city,
          routeBase: LocationFilters.CITIES.routeBase,
        }))
      )
      .then((cities) => {
        setCities(cities);
        setLocations((locations) => [...locations, ...cities]);
      });
    fetch("/data/hotels.json")
      .then((response) => response.json())
      .then((hotels) =>
        hotels.map((hotel) => ({
          ...hotel,
          routeBase: LocationFilters.HOTELS.routeBase,
        }))
      )
      .then((hotels) => {
        setLocations((locations) => [...locations, ...hotels]);
      });
    fetch("/data/attractions.json")
      .then((response) => response.json())
      .then((attractions) =>
        attractions.map((attraction) => ({
          ...attraction,
          routeBase: LocationFilters.ATTRACTIONS.routeBase,
        }))
      )
      .then((attractions) => {
        setLocations((locations) => [...locations, ...attractions]);
      });
  }, []);

  return (
    <Router style={{ position: "relative" }}>
      <main
        style={{
          height: isMobileDevice
            ? `calc(${vh * 100}px - ${FOOTER_HEIGHT_REM}rem`
            : `calc(${vh * 100}px`,
          position: "relative",
        }}
      >
        <Switch>
          {[
            LocationFilters.CITIES.routeBase,
            LocationFilters.HOTELS.routeBase,
            LocationFilters.ATTRACTIONS.routeBase,
          ].map((routeBase) => (
            <Route
              path={`${routeBase}/:locationName`}
              children={
                <Location
                  locations={locations}
                  setBackgroundStyle={setBackgroundStyle}
                />
              }
            />
          ))}
          <Route
            path="/mapa/:locationName"
            children={
              <PanoMap
                cities={cities}
                locationFilter={locationFilter}
                setBackgroundStyle={setBackgroundStyle}
                setLocationFilter={setLocationFilter}
              />
            }
          />
          <Route path="/mapa">
            <PanoMap
              cities={cities}
              locationFilter={locationFilter}
              setBackgroundStyle={setBackgroundStyle}
              setLocationFilter={setLocationFilter}
            />
          </Route>
          <Route path="/szukaj">
            <Search
              cities={cities}
              locationFilter={locationFilter}
              setBackgroundStyle={setBackgroundStyle}
              setLocationFilter={setLocationFilter}
            />
          </Route>
          <Route path="/dolacz-do-projektu">
            <About setBackgroundStyle={setBackgroundStyle} />
          </Route>
          <Route path="/">
            <Location
              locations={locations}
              introMode={introMode}
              setIntroMode={setIntroMode}
              setBackgroundStyle={setBackgroundStyle}
            />
          </Route>
        </Switch>
      </main>
      {isMobileDevice ? (
        <Footer />
      ) : (
        <DesktopFooter backgroundStyle={backgroundStyle} />
      )}
      {isDesktopOrLaptop && (
        <div className="absolute top-0 right-0 mt-6 mr-24">
          <LangSwitcher backgroundStyle={backgroundStyle} />
        </div>
      )}
    </Router>
  );
};

export default App;
