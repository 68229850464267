import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Link, useHistory } from "react-router-dom";
import { BackgroundStyle } from "../App";
import {
  PinIcon,
  PlayIcon,
  PauseIcon,
  SearchIcon,
  ArrowLeftIcon,
  FilterIcon,
} from "../icons";
import Pano from "../Pano/Pano";
import FilterDrawer from "./FilterDrawer";
import { isSafari, LocationFilters } from "../utils";

const CityFilters = {
  BIG_CITY: { id: "BIG_CITY", i18n_key: "Big cities" },
};

const Search = ({
  cities,
  setBackgroundStyle,
  locationFilter,
  setLocationFilter,
}) => {
  const { t } = useTranslation();
  const [activePanoIndex, setActivePanoIndex] = useState(null);
  const [elements, setElements] = useState([]);
  const [cityFilters, setCityFilters] = useState({
    [CityFilters.BIG_CITY.id]: { ...CityFilters.BIG_CITY, active: false },
  });
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [searchPhrase, setSearchPhrase] = useState(null);
  const history = useHistory();
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)",
  });

  useEffect(() => {
    switch (locationFilter) {
      case LocationFilters.HOTELS:
        fetch("/data/hotels.json")
          // TODO refactor as it repeats with attractions
          .then((response) => response.json())
          .then((hotels) =>
            hotels.map((hotel) => {
              const hotelCity = cities.find((city) => city.id === hotel.cityId);
              return {
                ...hotel,
                city: hotelCity && {
                  name: hotelCity.name,
                  route: hotelCity.route,
                },
                routeBase: LocationFilters.HOTELS.routeBase,
              };
            })
          )
          .then((hotels) =>
            fetch("/data/attractions.json")
              .then((response) => response.json())
              .then((attractions) =>
                attractions.map((attraction) => {
                  const attractionCity = cities.find(
                    (city) => city.id === attraction.cityId
                  );
                  return {
                    ...attraction,
                    city: attractionCity && {
                      name: attractionCity.name,
                      route: attractionCity.route,
                    },
                    routeBase: LocationFilters.ATTRACTIONS.routeBase,
                  };
                })
              )
              .then((attractions) => {
                return [...hotels, ...attractions].sort((a, b) =>
                  a.name.localeCompare(b.name)
                );
              })
              .then((elements) => {
                setElements(
                  searchPhrase
                    ? elements.filter((location) => {
                        const test = (name, phrase) =>
                          name.toLowerCase().includes(phrase.toLowerCase());
                        return [
                          location.name,
                          location.city.name,
                        ].some((name) => test(name, searchPhrase));
                      })
                    : elements
                );
              })
          );
        break;
      default:
        const activeFilterKeys = Object.keys(cityFilters).filter(
          (filterKey) => !!cityFilters[filterKey].active
        );

        const activeFiltersCities = cities
          .filter((city) =>
            activeFilterKeys.length > 0
              ? activeFilterKeys.every((filterKey) =>
                  city.tags.includes(filterKey)
                )
              : true
          )
          .sort((a, b) => a.name.localeCompare(b.name));

        const elementsToSet = searchPhrase
          ? activeFiltersCities.filter((location) => {
              const test = (name, phrase) =>
                name.toLowerCase().includes(phrase.toLowerCase());
              return locationFilter.id === LocationFilters.HOTELS.id
                ? [location.name, location.city.name].some((name) =>
                    test(name, searchPhrase)
                  )
                : test(location.name, searchPhrase);
            })
          : activeFiltersCities;
        setElements(elementsToSet);
    }
  }, [cities, cityFilters, searchPhrase, locationFilter]);

  useEffect(() => {
    setBackgroundStyle(BackgroundStyle.LIGHT);
  }, [setBackgroundStyle]);

  const toggleFilter = (filterId) => () => {
    setCityFilters({
      ...cityFilters,
      [filterId]: {
        ...cityFilters[filterId],
        active: !cityFilters[filterId].active,
      },
    });
  };

  const onSearchPhraseChange = (event) => {
    setSearchPhrase(event.target.value);
  };

  const formatLabel = (label, value) =>
    !value ? (
      label
    ) : (
      <span>
        {label
          .split(new RegExp(value, "gi"))
          .reduce((aggregated, current, i) => {
            const getIndex = (label, current) =>
              label.toLowerCase().indexOf(current.toLowerCase());
            return !i
              ? [current]
              : aggregated.concat(
                  <b className="text-green-600" key={value + current}>
                    {label.substr(getIndex(label, value), value.length)}
                  </b>,
                  current
                );
          }, [])}
      </span>
    );

  return (
    <div
      className={`m-auto h-full flex flex-col pt-3 ${
        isDesktopOrLaptop && "pb-20"
      } ${!isDesktopOrLaptop && "px-3"}`}
      style={{ maxWidth: "54rem" }}
    >
      <nav className="flex flex-row w-full items-center justify-between">
        <button
          onClick={() => history.goBack()}
          className="flex flex-row items-center flex-1 text-left hover:text-green-600 outline-none"
        >
          <ArrowLeftIcon />
          <span className="ml-2">{t("Back")}</span>
        </button>
        <h1 className="flex-1 justify-center">{t("Search")}</h1>
        <button
          className="flex flex-1 justify-end hover:text-green-600 items-center outline-none"
          role="img"
          onClick={() => setDrawerVisible(true)}
        >
          <span>{t("Filters")}</span>
          <FilterIcon className="ml-2 small" />
        </button>
      </nav>
      <main className="overflow-y-auto w-full items-center flex flex-col mt-4">
        <div className="bg-gray-100 rounded-full w-full flex flex-row justify-between border-gray-300 border mb-3 text-sm">
          {[LocationFilters.CITIES.id, LocationFilters.HOTELS.id].map(
            (filterId, idx, arr) => (
              <button
                key={idx}
                onClick={() => setLocationFilter(LocationFilters[filterId])}
                className={`${
                  locationFilter.id === filterId
                    ? "bg-green-600 text-white ml-0"
                    : "text-gray-700"
                } ${idx === 0 && "ml-0"} ${
                  idx === arr.length - 1 && "mr-0"
                } text-center rounded-full px-6 py-1 flex-1 mx-1 last:mr-0`}
              >
                {t(LocationFilters[filterId].i18n_key)}
                {filterId === LocationFilters.HOTELS.id && (
                  <>
                    &nbsp;{t("&")}&nbsp;
                    {t("Attractions")}
                  </>
                )}
              </button>
            )
          )}
        </div>
        <div className="w-full flex flex-row items-center relative">
          <input
            onChange={onSearchPhraseChange}
            placeholder={
              locationFilter.id === LocationFilters.CITIES.id
                ? t("Enter the phrase.City Only")
                : t("Enter the phrase.Location")
            }
            className="bg-gray-100 rounded-full w-full p-3 border-gray-300 border text-sm mb-3 outline-none"
          />
          <SearchIcon className="mx-3 text-gray-500 absolute right-0 mb-3" />
        </div>
        {elements.length > 0 && (
          <h2 className="text-gray-500 text-xs my-1 self-center">
            {t("Found")}: {elements.length}
          </h2>
        )}
        {elements.length > 0 ? (
          <div className="w-full">
            {elements.map((location, index) => (
              <div
                key={index}
                className="flex flex-wrap flex-row pb-3 bg-gray-100 bg-opacity-50 my-3 rounded-lg text-gray-700 hover:text-green-500"
              >
                <div className="flex-1 flex flex-col px-3">
                  <div className="flex flex-row pt-3">
                    <Link
                      to={`${locationFilter.routeBase}/${location.route}`}
                      className="underline text-lg mr-4 flex-1"
                    >
                      {formatLabel(location.name, searchPhrase)}
                    </Link>
                  </div>
                  <div className="flex pt-1">
                    {location.city && location.city.route && (
                      <Link
                        to={`${LocationFilters.CITIES.routeBase}/${location.city.route}`}
                        className="bg-gray-200 text-gray-600 text-sm flex flex-row rounded-full px-3 py-1 mr-2"
                      >
                        <PinIcon />
                        <span className="ml-2 underline">
                          {formatLabel(location.city.name, searchPhrase)}
                        </span>
                      </Link>
                    )}
                    {location.tags && location.tags.length > 0 && (
                      <div
                        className="flex items-center"
                        style={{ minWidth: "15rem" }}
                      >
                        <span className="text-xs text-gray-500 mr-2">
                          {t("Tags").toUpperCase()}:
                        </span>
                        {location.tags &&
                          location.tags.map((tag, idx) => (
                            <button
                              onClick={toggleFilter(tag)}
                              key={idx}
                              className={`${
                                idx > 0 && "ml-2"
                              } bg-gray-200 text-gray-600 whitespace-no-wrap text-sm flex flex-row rounded-full px-3 py-1 outline-none`}
                            >
                              <span>{t(CityFilters[tag].i18n_key)}</span>
                            </button>
                          ))}
                      </div>
                    )}
                  </div>
                </div>
                <Link
                  to={`${location.routeBase}/${location.route}`}
                  className="flex-1 mx-3 pt-3"
                  style={{
                    minWidth: "10rem",
                    maxWidth: isDesktopOrLaptop ? "16rem" : null,
                  }}
                >
                  <div
                    className="bg-gray-400 rounded-lg h-24 relative"
                    style={{ width: "100%" }}
                    onMouseOver={(_) =>
                      isDesktopOrLaptop ? setActivePanoIndex(index) : null
                    }
                    onMouseLeave={(_) =>
                      isDesktopOrLaptop ? setActivePanoIndex(null) : null
                    }
                  >
                    {isDesktopOrLaptop && activePanoIndex === index ? (
                      <>
                        <PauseIcon className="big text-white absolute top-0 left-0 pl-2 pt-2 z-10" />
                        <Pano
                          route={`${location.routeBase}/${location.route}_sm`}
                          setPanoReady={() => null}
                          className="w-full"
                        />
                      </>
                    ) : (
                      <>
                        {isDesktopOrLaptop && (
                          <PlayIcon className="text-white big absolute top-0 left-0 pl-2 pt-2" />
                        )}
                        <img
                          alt={`${location.name} - thumbnail`}
                          src={`/panos${location.routeBase}/${
                            location.route
                          }_th${isSafari ? ".jpg" : ".webp"}`}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "0.5rem",
                          }}
                        />
                      </>
                    )}
                  </div>
                </Link>
              </div>
            ))}
          </div>
        ) : (
          <div className="flex flex-1 flex-col text-gray-500 mt-10">
            <span>
              {t("No city found")}&nbsp;
              <span role="img" aria-label="sad-face">
                😢
              </span>
            </span>
            <span>{t("Change search phrase")}</span>
          </div>
        )}
      </main>
      <FilterDrawer
        cityFilters={cityFilters}
        toggleFilter={toggleFilter}
        setDrawerVisible={setDrawerVisible}
        drawerVisible={drawerVisible}
      />
    </div>
  );
};

export default Search;
