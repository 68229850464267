export const simulateButtonPress = (e, pressed = true) => {
  e.target.style.boxShadow = `0px ${pressed ? "2px" : "5px"} 0px 0px #519400`;
  e.target.style.top = pressed ? "3px" : 0;
};

export const isSafari =
  navigator.vendor &&
  navigator.vendor.indexOf("Apple") > -1 &&
  navigator.userAgent &&
  navigator.userAgent.indexOf("CriOS") === -1 &&
  navigator.userAgent.indexOf("FxiOS") === -1;

export const LocationFilters = {
  CITIES: { id: "CITIES", i18n_key: "Cities", routeBase: "/miasta" },
  HOTELS: { id: "HOTELS", i18n_key: "Hotels", routeBase: "/hotele" },
  ATTRACTIONS: {
    id: "ATTRACTIONS",
    i18n_key: "Attractions",
    routeBase: "/atrakcje",
  },
};
