import React from "react";

const HomeIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    viewBox="0 0 512 512"
  >
    <path d="M506.2 236.4L297.8 26.6l-.8-.8c-22.8-20.4-57.1-20.5-80-.2-.3.3-.6.5-.8.8L5.9 236.4c-7.8 7.8-7.8 20.5 0 28.3 7.8 7.8 20.5 7.8 28.3 0L50 248.8v172.7c0 44.1 35.9 80 80 80h72c11 0 20-9 20-20v-163h70v163c0 11 9 20 20 20h70c44.1 0 80-35.9 80-80 0-11-9-20-20-20s-20 9-20 20c0 22.1-17.9 40-40 40h-50v-163c0-11-9-20-20-20H202c-11 0-20 9-20 20v163h-52c-22.1 0-40-17.9-40-40v-212-.6L244 55.2c7.5-6.4 18.5-6.3 25.9.1L422 208.4v113.1c0 11 9 20 20 20s20-9 20-20v-72.8l15.8 15.9c3.9 3.9 9 5.9 14.2 5.9 5.1 0 10.2-1.9 14.1-5.8 7.8-7.8 7.9-20.5.1-28.3z" />
  </svg>
);

const PinIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className={className}
  >
    <path d="M333.9 332.8l55.5-78C435.2 190.3 427.9 103 371.9 47 309-15.8 206.6-15.8 143.7 47c-55.9 56-63.3 143.4-17.4 207.8l55.1 77.4c-68.2 12.1-122.9 41.3-122.9 86.6C58.4 480 157.8 512 256 512s197.6-32 197.6-93.1c0-44.6-53-73.6-119.7-86.1zM161.1 230.1c-33.7-47.4-28.3-111.7 12.9-152.8 23.1-23.1 53.5-34.7 83.9-34.7s60.7 11.6 83.9 34.6c41.2 41.1 46.6 105.4 12.9 152.8l-96.8 136-96.8-135.9zM256 469.3c-100.2 0-154.8-33.3-154.8-50.4 0-14.2 38-39.3 107.9-47.5l31.2 43.9c4 5.6 10.5 9 17.4 9 6.9 0 13.4-3.3 17.4-8.9l31-43.5c67.9 8.6 104.7 33.2 104.7 47.1.1 17-54.6 50.3-154.8 50.3z" />
    <path d="M257.7 88.3c-40.3 0-73 32.8-73 73 0 40.3 32.8 73 73 73 40.3 0 73-32.8 73-73 .1-40.3-32.7-73-73-73zm0 103.3c-16.7 0-30.3-13.6-30.3-30.3S241 131 257.7 131s30.3 13.6 30.3 30.3-13.6 30.3-30.3 30.3z" />
  </svg>
);

const MailIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className={className}
  >
    <path d="M4 4h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2zm16 3.38V6H4v1.38l8 4 8-4zm0 2.24l-7.55 3.77a1 1 0 0 1-.9 0L4 9.62V18h16V9.62z" />
  </svg>
);

const SearchIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    viewBox="0 0 30.2 30.2"
  >
    <path d="M20.2 3.5c-4.6-4.6-12.1-4.6-16.7 0s-4.6 12.1 0 16.7c4.1 4.1 10.5 4.5 15.1 1.3.1.5.3.9.7 1.3l6.7 6.7c1 1 2.6 1 3.5 0 1-1 1-2.6 0-3.5l-6.7-6.7c-.4-.4-.8-.6-1.3-.7 3.2-4.6 2.8-11-1.3-15.1zm-2.1 14.6c-3.4 3.4-9 3.4-12.5 0-3.4-3.4-3.4-9 0-12.5 3.4-3.4 9-3.4 12.5 0 3.4 3.4 3.4 9 0 12.5z" />
  </svg>
);

const InfoIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    viewBox="0 0 512 512"
  >
    <path d="M256 182.8c-12.7 0-21.8 5.4-21.8 13.3v107.7c0 6.8 9 13.6 21.8 13.6 12.2 0 22-6.8 22-13.6V196.1c0-8-9.8-13.3-22-13.3zM256 116.4c-13 0-23.2 9.3-23.2 20.1s10.2 20.3 23.2 20.3c12.7 0 22.9-9.6 22.9-20.3 0-10.8-10.2-20.1-22.9-20.1z" />
    <path d="M256 0C134.4 0 36 98.3 36 219.9c0 99.2 65.3 181.6 153.4 209.6l50 73.7c3.7 5.5 9.9 8.8 16.6 8.8s12.8-3.3 16.6-8.8l50-73.7c88.1-28 153.4-110.4 153.4-209.6C476 98.3 377.5 0 256 0zm48.6 393.1c-4.6 1.3-8.5 4.1-11.2 8L256 456.4l-37.5-55.2c-2.7-3.9-6.6-6.8-11.2-8-75-21-131.4-89.9-131.4-173.2C76 120.7 156.7 40 256 40s180 80.7 180 179.9c0 83.4-56.5 152.3-131.4 173.2z" />
  </svg>
);

const PhoneIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    viewBox="0 0 24 24"
  >
    <path d="M13.04 14.69l1.07-2.14a1 1 0 0 1 1.2-.5l6 2A1 1 0 0 1 22 15v5a2 2 0 0 1-2 2h-2A16 16 0 0 1 2 6V4c0-1.1.9-2 2-2h5a1 1 0 0 1 .95.68l2 6a1 1 0 0 1-.5 1.21L9.3 10.96a10.05 10.05 0 0 0 3.73 3.73zM8.28 4H4v2a14 14 0 0 0 14 14h2v-4.28l-4.5-1.5-1.12 2.26a1 1 0 0 1-1.3.46 12.04 12.04 0 0 1-6.02-6.01 1 1 0 0 1 .46-1.3l2.26-1.14L8.28 4z" />
  </svg>
);

const CarIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className={className}
  >
    <path d="M2 14v-3H1a1 1 0 0 1-1-1 1 1 0 0 1 1-1h1l4-7h8l4 7h1a1 1 0 0 1 1 1 1 1 0 0 1-1 1h-1v6a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1v-1H5v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-3zm13.86-5L13 4H7L4.14 9h11.72zM5.5 14a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm9 0a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
  </svg>
);

const FilterIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    className={className}
  >
    <path d="M12 12l8-8V0H0v4l8 8v8l4-4v-4z" />
  </svg>
);

const ShuffleIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    className={className}
  >
    <path d="M6.59 12.83L4.4 15c-.58.58-1.59 1-2.4 1H0v-2h2c.29 0 .8-.2 1-.41l2.17-2.18 1.42 1.42zM16 4V1l4 4-4 4V6h-2c-.29 0-.8.2-1 .41l-2.17 2.18L9.4 7.17 11.6 5c.58-.58 1.59-1 2.41-1h2zm0 10v-3l4 4-4 4v-3h-2c-.82 0-1.83-.42-2.41-1l-8.6-8.59C2.8 6.21 2.3 6 2 6H0V4h2c.82 0 1.83.42 2.41 1l8.6 8.59c.2.2.7.41.99.41h2z" />
  </svg>
);

const CheckmarkIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    className={className}
  >
    <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
  </svg>
);

const ArrowLeftIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className={className}
  >
    <path d="M5.41 11H21a1 1 0 0 1 0 2H5.41l5.3 5.3a1 1 0 0 1-1.42 1.4l-7-7a1 1 0 0 1 0-1.4l7-7a1 1 0 0 1 1.42 1.4L5.4 11z" />
  </svg>
);

const ArrowRightIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className={className}
  >
    <path d="M18.59 13H3a1 1 0 0 1 0-2h15.59l-5.3-5.3a1 1 0 1 1 1.42-1.4l7 7a1 1 0 0 1 0 1.4l-7 7a1 1 0 0 1-1.42-1.4l5.3-5.3z" />
  </svg>
);

const CloseIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    className={className}
  >
    <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
  </svg>
);

const GoogleIcon = ({ className }) => (
  <svg
    viewBox="0 0 533.5 544.3"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M533.5 278.4c0-18.5-1.5-37.1-4.7-55.3H272.1v104.8h147c-6.1 33.8-25.7 63.7-54.4 82.7v68h87.7c51.5-47.4 81.1-117.4 81.1-200.2z"
      fill="#4285f4"
    />
    <path
      d="M272.1 544.3c73.4 0 135.3-24.1 180.4-65.7l-87.7-68c-24.4 16.6-55.9 26-92.6 26-71 0-131.2-47.9-152.8-112.3H28.9v70.1c46.2 91.9 140.3 149.9 243.2 149.9z"
      fill="#34a853"
    />
    <path
      d="M119.3 324.3c-11.4-33.8-11.4-70.4 0-104.2V150H28.9c-38.6 76.9-38.6 167.5 0 244.4l90.4-70.1z"
      fill="#fbbc04"
    />
    <path
      d="M272.1 107.7c38.8-.6 76.3 14 104.4 40.8l77.7-77.7C405 24.6 339.7-.8 272.1 0 169.2 0 75.1 58 28.9 150l90.4 70.1c21.5-64.5 81.8-112.4 152.8-112.4z"
      fill="#ea4335"
    />
  </svg>
);

const BookmarkIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className={className}
  >
    <path d="M12 18.62l-6.55 3.27A1 1 0 0 1 4 21V4c0-1.1.9-2 2-2h12a2 2 0 0 1 2 2v17a1 1 0 0 1-1.45.9L12 18.61zM18 4H6v15.38l5.55-2.77a1 1 0 0 1 .9 0L18 19.38V4z" />
  </svg>
);

const PlayIcon = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
  >
    <path d="M2.93 17.07A10 10 0 1117.07 2.93 10 10 0 012.93 17.07zm12.73-1.41A8 8 0 104.34 4.34a8 8 0 0011.32 11.32zM7 6l8 4-8 4V6z" />
  </svg>
);

const PauseIcon = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
  >
    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM7 6h2v8H7V6zm4 0h2v8h-2V6z" />
  </svg>
);

const FlagIcon = ({ country, className }) => {
  switch (country) {
    case "en":
      return (
        <svg
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          style={{ fill: "none" }}
        >
          <mask
            id="a"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <rect width="24" height="24" rx="12" fill="#E8E8E8" />
          </mask>
          <g mask="url(#a)">
            <g clipPath="url(#clip0)">
              <path d="M-8 2h39v20H-8V2z" fill="#012169" />
              <path
                d="M-8 2v2l35 18h4v-2L-3 2h-5zm39 0v2L-3 22h-5v-2L27 2h4z"
                fill="#fff"
              />
              <path d="M9 2v20h6V2H9zM-8 9v6h39V9H-8z" fill="#fff" />
              <path
                d="M-8 10v4h40v-4H-8zm18-8v20h4V2h-4zM-8 22l13-7h3l-13 7h-3zm0-20L8 9H5L-8 3V2zm24 7l13-7h3L19 9h-3zm16 13l-16-7h3l13 6v1z"
                fill="#C8102E"
              />
            </g>
            <rect
              x="1"
              y="1"
              width="22"
              height="22"
              rx="11"
              stroke="#BDBDBD"
              strokeWidth="2"
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <path fill="#fff" transform="translate(-1 2)" d="M0 0h27v20H0z" />
            </clipPath>
          </defs>
        </svg>
      );
    case "de":
      return (
        <svg
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          style={{ fill: "none" }}
        >
          <mask
            id="a"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <rect width="24" height="24" rx="12" fill="#E8E8E8" />
          </mask>
          <g mask="url(#a)">
            <g clipPath="url(#clip0)">
              <path d="M-1 15h27v7H-1v-7z" fill="#FFCE00" />
              <path d="M-1 2h27v7H-1V2z" fill="#000" />
              <path d="M-1 9h27v6H-1V9z" fill="#D00" />
            </g>
            <rect
              x="1"
              y="1"
              width="22"
              height="22"
              rx="11"
              stroke="#BDBDBD"
              strokeWidth="2"
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <path fill="#fff" transform="translate(-1 2)" d="M0 0h27v20H0z" />
            </clipPath>
          </defs>
        </svg>
      );
    default:
      return (
        <svg
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          style={{ fill: "none" }}
        >
          <mask
            id="a"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <rect width="24" height="24" rx="12" fill="#E8E8E8" />
          </mask>
          <g mask="url(#a)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M22 22H-4V2h26v20z"
              fill="#fff"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M22 22H-4V12h26v10z"
              fill="#DC143C"
            />
            <rect
              x="1"
              y="1"
              width="22"
              height="22"
              rx="11"
              stroke="#BDBDBD"
              strokeWidth="2"
            />
          </g>
        </svg>
      );
  }
};

const StarIcon = ({ content, className }) => {
  switch (content) {
    case "empty":
      return (
        <svg
          fill="none"
          className={className}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 15 14"
        >
          <path
            d="M4.36 14.47a.658.658 0 01-.954-.698l.678-3.97-2.883-2.804a.658.658 0 01.369-1.126l3.982-.579L7.33 1.685a.658.658 0 011.185 0l1.777 3.608 3.99.58a.658.658 0 01.362 1.119l-2.883 2.81.684 3.97a.659.659 0 01-.961.698l-3.555-1.876-3.568 1.876zm3.26-3.206a.658.658 0 01.612 0l2.686 1.416-.514-2.996a.657.657 0 01.191-.58l2.173-2.12-3.002-.44a.658.658 0 01-.5-.356L7.921 3.463 6.58 6.188a.658.658 0 01-.493.356l-3.009.44 2.173 2.12a.658.658 0 01.197.58l-.52 2.996 2.693-1.416z"
            fill="#999"
          />
        </svg>
      );
    case "half":
      return (
        <svg
          fill="none"
          className={className}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
        >
          <path
            d="M4.36 14.47a.658.658 0 01-.954-.698l.678-3.97-2.883-2.804a.658.658 0 01.369-1.126l3.982-.579L7.33 1.685a.658.658 0 011.185 0l1.777 3.608 3.99.58a.658.658 0 01.362 1.119l-2.883 2.81.684 3.97a.659.659 0 01-.961.698l-3.555-1.876-3.568 1.876zm3.26-3.206a.658.658 0 01.612 0l2.686 1.416-.514-2.996a.657.657 0 01.191-.58l2.173-2.12-3.002-.44a.658.658 0 01-.5-.356L7.921 3.463 6.58 6.188a.658.658 0 01-.493.356l-3.009.44 2.173 2.12a.658.658 0 01.197.58l-.52 2.996 2.693-1.416z"
            fill="#999"
          />
          <path
            d="M4.006 14.544a.658.658 0 00.355-.074l3.568-1.876-.007-11.28a.658.658 0 00-.592.371L5.552 5.293l-3.982.58a.658.658 0 00-.37 1.125l2.884 2.805-.678 3.97a.658.658 0 00.6.771z"
            fill="#FFCE00"
          />
        </svg>
      );
    default:
      return (
        <svg
          fill="none"
          className={className}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 15 14"
        >
          <path
            d="M4.016 13.47a.658.658 0 01-.955-.698l.678-3.97L.856 5.999a.658.658 0 01.368-1.126l3.983-.579L6.985.685a.658.658 0 011.185 0l1.777 3.608 3.99.58a.658.658 0 01.362 1.119l-2.884 2.81.685 3.97a.659.659 0 01-.961.698l-3.555-1.876-3.568 1.876z"
            fill="#FFCE00"
          />
        </svg>
      );
  }
};

export {
  ArrowLeftIcon,
  ArrowRightIcon,
  BookmarkIcon,
  CarIcon,
  CheckmarkIcon,
  CloseIcon,
  FilterIcon,
  FlagIcon,
  GoogleIcon,
  HomeIcon,
  InfoIcon,
  MailIcon,
  PauseIcon,
  PhoneIcon,
  PinIcon,
  PlayIcon,
  SearchIcon,
  ShuffleIcon,
  StarIcon,
};
