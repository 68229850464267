import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

export const AvailableLangs = [
  { shortcode: "pl", name: "Polski" },
  { shortcode: "de", name: "Deutsch" },
  { shortcode: "en", name: "English" },
];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    react: {
      useSuspense: false,
    },
    fallbackLng: AvailableLangs.map((lang) => lang.shortcode),
    interpolation: {
      escapeValue: false,
    },
  });
