import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { BookmarkIcon, PinIcon, MailIcon, PhoneIcon } from "./icons";
import LightMouseLogo from "./lightmouse-logo.svg";
import { BackgroundStyle } from "./App";
import { useMediaQuery } from "react-responsive";

const About = ({ setBackgroundStyle }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)",
  });

  useEffect(() => {
    setBackgroundStyle(BackgroundStyle.LIGHT);
  }, [setBackgroundStyle]);

  return ["de", "en"].includes(i18n.language) ? (
    <section className="h-full w-full flex flex-col items-center pt-3 px-5 sm:px-48">
      <nav className="flex flex-row w-full items-center justify-evenly mb-6">
        <button
          onClick={() => history.goBack()}
          className="text-green-600 flex-1 text-left"
        >
          {t("Back")}
        </button>
        <h1>{i18n.language === "de" ? t("Company Info") : t("About")}</h1>
        <span className="flex flex-1 justify-end opacity-0" role="img"></span>
      </nav>
      <main
        className="flex flex-col justify-center items-center h-full absolute top-0 px-5 text-base sm:text-xl"
        style={{ color: "#2d4059" }}
      >
        <img
          alt="Lightmouse - logo"
          src={LightMouseLogo}
          style={{ width: "50%", height: "auto" }}
        />
        <div className="w-full">
          <div className="flex flex-row mt-4">
            <PinIcon className="mt-1 sm:mt-2" />
            <div className="flex flex-col flex-1">
              <span className="ml-2">ul. Korzeniowskiego 1 (wejście od 2)</span>
              <span className="ml-2">70-211 Szczecin</span>
            </div>
          </div>
          <div className="flex flex-row justify-start mt-2">
            <BookmarkIcon className="mt-1 sm:mt-2" />
            <a
              href="https://lightmouse.pl"
              target="blank"
              className="ml-2 flex-1 underline"
            >
              lightmouse.pl
            </a>
          </div>
          <div className="flex flex-row justify-start mt-2">
            <MailIcon className="mt-1 sm:mt-2" />
            <a
              href="mailto:hello@lightmouse.pl"
              className="ml-2 flex-1 underline"
            >
              hello@lightmouse.pl
            </a>
          </div>
          <div className="flex flex-row justify-start mt-2">
            <PhoneIcon className="mt-1 sm:mt-2" />
            <div className="flex flex-col flex-1 justify-end ml-2">
              <a href="tel:+48 91 434 21 87" className="flex-shrink underline">
                +48 91 434 21 87
              </a>
              <a href="tel:+48 91 433 70 03" className="flex-shrink underline">
                +48 91 433 70 03
              </a>
            </div>
          </div>
        </div>
      </main>
    </section>
  ) : (
    <iframe
      title="Wielkopolska 360 - dołącz do projektu"
      src="https://lightmouse.pl/wirtualne-regiony/"
      className={`w-full h-full ${isDesktopOrLaptop && "pb-20"}`}
      frameBorder="0"
    ></iframe>
  );
};

export default About;
