import { makeStyles } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import React from "react";
import { useTranslation } from "react-i18next";

const FilterDrawer = ({
  cityFilters,
  toggleFilter,
  drawerVisible,
  setDrawerVisible,
}) => {
  const { t } = useTranslation();

  const drawerStyles = makeStyles({
    paper: {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  });

  return (
    <Drawer
      anchor="top"
      open={drawerVisible}
      onClose={() => setDrawerVisible(false)}
      classes={{
        paper: drawerStyles().paper,
      }}
    >
      <div className="rounded-b-lg bg-gray-100 flex flex-col self-center w-full lg:w-10/12 lg:max-w-4xl p-3">
        <span className="text-xs text-gray-500 py-2">
          {t("Filters").toUpperCase()}:
        </span>
        <div className="flex flex-row w-full flex-wrap justify-between items-center">
          {Object.keys(cityFilters).map((filterKey, index) => (
            <button
              onClick={toggleFilter(filterKey)}
              key={index}
              className={`rounded-full whitespace-no-wrap font-medium tracking-wider border-gray-300 border ${
                index > 0 ? "flex-1" : "flex-grow"
              } m-1 text-sm py-1 px-5 ${
                cityFilters[filterKey].active
                  ? "text-white bg-green-600"
                  : "text-green-600 bg-gray-100"
              }`}
            >
              {t(cityFilters[filterKey].i18n_key)}
            </button>
          ))}
        </div>
      </div>
    </Drawer>
  );
};

export default FilterDrawer;
