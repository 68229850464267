import { makeStyles } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import React from "react";
import { useTranslation } from "react-i18next";
import { AvailableLangs } from "../i18n";
import { CheckmarkIcon, FlagIcon } from "../icons";

const LangSwitcher = ({ drawerVisible, setDrawerVisible }) => {
  const { i18n, t } = useTranslation();

  const drawerStyles = makeStyles({
    paper: {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  });

  const langSelectionHandler = (lang) => {
    i18n.changeLanguage(lang);
    setDrawerVisible(false);
  };

  return (
    <Drawer
      anchor="bottom"
      open={drawerVisible}
      onClose={() => setDrawerVisible(false)}
      classes={{
        paper: drawerStyles().paper,
      }}
    >
      <div className="rounded-t-lg bg-gray-100 flex flex-col self-center w-full lg:w-10/12 lg:max-w-4xl px-3">
        <span className="text-xs text-gray-500 pt-2">
          {t("Choose language").toUpperCase()}:
        </span>
        {AvailableLangs.map((lang, index) => {
          return (
            <button
              className={`flex justify-between text-gray-700 border-b p-3 border-gray-300 last:border-b-0 hover:text-green-600 ${
                i18n.language === lang.shortcode ? "text-green-600" : null
              }`}
              key={index}
              onClick={() => langSelectionHandler(lang.shortcode)}
            >
              <div className="flex flex-row items-center">
                <FlagIcon country={lang.shortcode} className="mr-6" />
                <span className="font-sans font-bold">{lang.name}</span>
              </div>
              {i18n.language === lang.shortcode ? (
                <CheckmarkIcon className="text-green-600" />
              ) : null}
            </button>
          );
        })}
      </div>
    </Drawer>
  );
};

export default LangSwitcher;
