import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { ArrowLeftIcon, CloseIcon } from "../icons";
import { simulateButtonPress } from "../utils";

const LocationPopup = ({ location, popupVisible, setPopupVisible }) => {
  const { t } = useTranslation();
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)",
  });

  useEffect(() => {
    setPopupVisible(!!location);
    return () => {};
  }, [location, setPopupVisible]);

  return popupVisible ? (
    <div
      className={`absolute bottom-0 px-6 pt-3 pb-5 rounded-lg bg-gray-100 shadow-lg ${
        isDesktopOrLaptop ? "mb-24" : "mb-6"
      }`}
    >
      <button
        className="absolute top-0 right-0 mr-2 mt-2"
        onClick={() => setPopupVisible(false)}
      >
        <CloseIcon />
      </button>
      <div className="flex flex-col items-center">
        <h1>{location.name}</h1>
        <div className="flex flex-col">
          <a
            target="blank"
            href={`https://${location.url}`}
            onMouseOver={(e) => simulateButtonPress(e)}
            onMouseLeave={(e) => simulateButtonPress(e, false)}
            className="rounded-full btn-thick relative py-3 px-4 text-sm xs:text-base xs:px-6 sm:px-8 bg-green-600 text-white mt-2 mb-6 flex-1 flex flex-row items-center"
          >
            {t("3d walk").toUpperCase()}
          </a>
          <Link
            to={`${location.routeBase}/${location.route}`}
            className="flex flex-row rounded-full py-3 px-4 text-sm xs:text-base xs:px-6 sm:px-8 bg-gray-200 text-gray-700 flex-1 justify-center items-center"
          >
            <ArrowLeftIcon className="mr-2" />
            <span className="font-bold">{t("Back").toUpperCase()}</span>
          </Link>
        </div>
      </div>
    </div>
  ) : null;
};

export default LocationPopup;
