import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { FlagIcon, HomeIcon, InfoIcon, PinIcon, SearchIcon } from "../icons";
import LangSwitcher from "./LangSwitcher";

export const FOOTER_HEIGHT_REM = "3.4";

const Footer = () => {
  const { t, i18n } = useTranslation();
  const [drawerVisible, setDrawerVisible] = useState(false);

  return (
    <div className="flex flex-col">
      <div
        className="w-full"
        style={{
          position: "relative",
          height: `${FOOTER_HEIGHT_REM / 2}rem`,
          top: `-${FOOTER_HEIGHT_REM / 2}rem`,
        }}
      />
      <nav
        style={styles}
        className="flex flex-row justify-around items-center bg-gray-100 lg:px-64 font-sans"
      >
        <NavLink
          exact
          to="/"
          className="hover:text-green-600 flex flex-col items-center"
          activeClassName="text-green-600"
        >
          <HomeIcon />
          <span className="text-xs justify-center">{t("Home")}</span>
        </NavLink>
        <NavLink
          to="/mapa"
          className="hover:text-green-600 flex flex-col items-center"
          activeClassName="text-green-600"
        >
          <PinIcon />
          <span className="text-xs">{t("Map")}</span>
        </NavLink>
        <NavLink
          exact
          to="/szukaj"
          className="hover:text-green-600 flex flex-col items-center"
          activeClassName="text-green-600"
        >
          <SearchIcon />
          <span className="text-xs">{t("Search")}</span>
        </NavLink>
        <NavLink
          exact
          to="/dolacz-do-projektu"
          className="hover:text-green-600 flex flex-col items-center"
          activeClassName="text-green-600"
        >
          <InfoIcon />
          <span className="text-xs">{t("About")}</span>
        </NavLink>
        <button
          onClick={() => setDrawerVisible(true)}
          className="flex flex-col items-center"
        >
          <FlagIcon country={i18n.language} />
          <span className="text-xs">
            {i18n.language && i18n.language.substring(0, 2).toUpperCase()}
          </span>
        </button>
        <LangSwitcher
          setDrawerVisible={setDrawerVisible}
          drawerVisible={drawerVisible}
        />
      </nav>
    </div>
  );
};

const styles = {
  position: "absolute",
  bottom: 0,
  width: "100%",
  height: `${FOOTER_HEIGHT_REM}rem`,
};

export default Footer;
