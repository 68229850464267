import React, { useEffect, useRef } from "react";
import { Viewer } from "photo-sphere-viewer";
import { useTranslation } from "react-i18next";
import { isSafari } from "../utils";

const Pano = ({ className, route, setPanoReady, isTouchEnabled = false }) => {
  const { t } = useTranslation();
  const loadingTxt = t("Loading");
  const DEFAULT_LAT = "-15deg";

  let panoViewerRef = useRef(null);

  useEffect(() => {
    const panoViewer = new Viewer({
      container: panoViewerRef.current,
      panorama: `/panos${route}${isSafari ? ".jpg" : ".webp"}`,
      loadingTxt,
      navbar: null,
      defaultLat: DEFAULT_LAT,
      autorotateDelay: 1,
      autorotateSpeed: "0.25rpm",
      mousewheel: false,
      mousemove: isTouchEnabled,
      defaultZoomLvl: 40,
    });

    panoViewer.on("ready", async (_) => setPanoReady(true));
    return () => panoViewer.destroy();
  }, [loadingTxt, panoViewerRef, route, setPanoReady, isTouchEnabled]);

  return <div ref={panoViewerRef} className={`w-full h-full ${className}`} />;
};

export default Pano;
