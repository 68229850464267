import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { BackgroundStyle } from "../App";
import { HomeIcon, InfoIcon, PinIcon, SearchIcon, FlagIcon } from "../icons";
import LangSwitcher from "./LangSwitcher";
import { AvailableLangs } from "../i18n";

export const FOOTER_HEIGHT_REM = "5";

const DesktopFooter = ({ backgroundStyle }) => {
  const { t, i18n } = useTranslation();
  const [drawerVisible, setDrawerVisible] = useState(false);

  const getLangName = () => {
    const i18nLang =
      i18n && i18n.language
        ? i18n.language.substring(0, 2).toLowerCase()
        : "en";
    const langObj = AvailableLangs.find((lang) => i18nLang === lang.shortcode);
    return langObj && langObj.name ? langObj.name : "";
  };

  return (
    <nav
      style={styles}
      className={`flex justify-center \
      absolute bottom-0`}
    >
      <div className="flex font-bold my-4 justify-between flex-row w-10/12 max-w-4xl">
        <NavLink
          exact
          to="/"
          className={`${
            backgroundStyle === BackgroundStyle.LIGHT
              ? "hover:bg-gray-700 hover:text-white"
              : "hover:text-gray-700 hover:bg-white"
          } rounded-full px-8 py-3 flex items-center ${
            backgroundStyle === BackgroundStyle.LIGHT
              ? "text-gray-700 bg-white"
              : "text-white"
          }`}
          activeClassName={
            backgroundStyle === BackgroundStyle.LIGHT
              ? "bg-gray-700 text-white"
              : "bg-white text-gray-700"
          }
        >
          <HomeIcon className="mr-6" />
          <span className="font-bold justify-center">{t("Home")}</span>
        </NavLink>
        <NavLink
          to="/mapa"
          className={`${
            backgroundStyle === BackgroundStyle.LIGHT
              ? "hover:bg-gray-700 hover:text-white"
              : "hover:text-gray-700 hover:bg-white"
          } rounded-full px-8 py-3 flex items-center ${
            backgroundStyle !== BackgroundStyle.LIGHT ? "text-white" : null
          }`}
          activeClassName={
            backgroundStyle === BackgroundStyle.LIGHT
              ? "bg-gray-700 text-white"
              : "bg-white text-gray-700"
          }
        >
          <PinIcon className="mr-6" />
          <span className="font-bold ">{t("Map")}</span>
        </NavLink>
        <NavLink
          exact
          to="/szukaj"
          className={`${
            backgroundStyle === BackgroundStyle.LIGHT
              ? "hover:bg-gray-700 hover:text-white"
              : "hover:text-gray-700 hover:bg-white"
          } rounded-full px-8 py-3 flex items-center ${
            backgroundStyle === BackgroundStyle.LIGHT
              ? "bg-white"
              : "text-white"
          }`}
          activeClassName={
            backgroundStyle === BackgroundStyle.LIGHT
              ? "bg-gray-700 text-white"
              : "bg-white text-gray-700"
          }
        >
          <SearchIcon className="mr-6" />
          <span className="font-bold ">{t("Search")}</span>
        </NavLink>
        <NavLink
          exact
          to="/dolacz-do-projektu"
          className={`${
            backgroundStyle === BackgroundStyle.LIGHT
              ? "hover:bg-gray-700 hover:text-white"
              : "hover:text-gray-700 hover:bg-white"
          } rounded-full px-8 py-3 flex items-center ${
            backgroundStyle === BackgroundStyle.LIGHT
              ? "bg-white"
              : "text-white"
          }`}
          activeClassName={
            backgroundStyle === BackgroundStyle.LIGHT
              ? "bg-gray-700 text-white"
              : "bg-white text-gray-700"
          }
        >
          <InfoIcon className="mr-6" />
          <span className="font-bold">{t("About")}</span>
        </NavLink>
        <button
          onClick={() => setDrawerVisible(true)}
          className={`${
            backgroundStyle === BackgroundStyle.LIGHT
              ? "hover:bg-gray-700 hover:text-white"
              : "hover:text-gray-700 hover:bg-white"
          } rounded-full px-8 py-3 flex items-center ${
            backgroundStyle === BackgroundStyle.LIGHT
              ? "bg-white"
              : "text-white"
          }`}
        >
          <FlagIcon
            country={
              i18n && i18n.language
                ? i18n.language.substring(0, 2).toLowerCase()
                : "en"
            }
            className="mr-6"
          />
          <span className="font-bold">{getLangName()}</span>
        </button>
        <LangSwitcher
          setDrawerVisible={setDrawerVisible}
          drawerVisible={drawerVisible}
        />
      </div>
    </nav>
  );
};

const styles = {
  height: `${FOOTER_HEIGHT_REM}rem`,
  right: "4rem",
  left: "4rem",
};

export default DesktopFooter;
